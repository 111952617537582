import React from 'react'
import { Navbar } from './navbar/Navbar'
import { Parallax } from './parallax/parallax-2/Parallax'

export const PaginaPrincipal = () => {
  return (
    <div>
    <Navbar />
    <Parallax />
    </div>
    
  )
}
